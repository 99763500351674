import React, { useContext } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Chip, Typography } from "@mui/material";
import Avatar from "react-avatar";
import JBWDateLabel from "./JBWDateLabel";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import CustomNoRowsOverlay from "./gridNoRowOverLay";
import SyncIcon from "@mui/icons-material/Sync";

const ClientGrid = ({ onboardDealers, onTenantSync }) => {
  const columns = [
    {
      field: "dealerAvatar",
      headerName: "Dealer Image",
      width: 150,
      renderCell: (param) => {
        return (
          <Avatar
            name={param.value.replace(/[^a-zA-Z ]/g, "")}
            maxInitials={2}
            size="60"
            round={true}
            title={param.value}
            style={{ marginTop: "5px", marginBottom: "5px" }}
          />
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dealerName",
      headerName: "Dealer Name",
      width: 250,
      sortable: false,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "dealerId",
      headerName: "Dealer ID",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "crmSystem",
      headerName: "Platform",
      width: 150,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "addressLine",
      headerName: "Location",
      width: 230,
      renderCell: (param) => {
        return (
          <Typography variant="subtitl1" component="span" color="primary">
            {param.value}
          </Typography>
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "createdOn",
      headerName: "Created Date",
      width: 150,
      renderCell: (param) => {
        return param.value ? (
          <JBWDateLabel inputDate={param.value} dateFormat="MMM.DD,YYYY" />
        ) : (
          ""
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "isOnboarded",
      headerName: "Status",
      width: 150,
      renderCell: (param) => {
        //console.log(param);
        const bgColor = param.value === 1 ? "#4ECC72" : "#DEB729";
        return (
          <Chip
            sx={{
              color: "#fff",
              backgroundColor: bgColor,
              mt: 1,
              mb: 1,
              width: "100%",
            }}
            label={param.value === 1 ? "Active" : "InActive"}
          />
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      cellClassName: "actions",
      width: 150,
      headerAlign: "center",
      align: "center",
      getActions: (param) => {
        //console.log("Actions :", param.row);
        const syncStatu = param.row.syncStatus;
        return [
          <GridActionsCellItem
            icon={<SyncIcon />}
            label="Sync On"
            className="textPrimary"
            onClick={handleSync(param)}
            color="secondary"
            title="Sync On"
            disabled={syncStatu}
          />,
        ];
      },
      sortable: false,
    },
  ];

  const { setDealer } = useContext(AuthContext);
  let navigate = useNavigate();
  const handleRowClick = (params, event, details) => {
    console.log("dealer row clicked params :", params.row);
    const selectedDealer = params.row;
    const dealerData = {
      id: selectedDealer.id,
      dealerId: selectedDealer.dealerId,
      dealerName: selectedDealer.dealerName,
      crmSystem: selectedDealer.crmSystem,
      addressline_1: selectedDealer.addressLine,
      city: selectedDealer.city,
      state: selectedDealer.state,
      zip: selectedDealer.zip,
      registeredName: selectedDealer.registeredName,
      registeredEmail: selectedDealer.registeredEmail,
      isActive: selectedDealer.isActive,
    };
    setDealer(dealerData);
    localStorage.setItem("dealer", JSON.stringify(dealerData));
    navigate(`/home/dealerdetails`);
  };

  const handleSync = (param) => () => {
    //console.log("Sync Clicked ", param.row);
    onTenantSync(param.row);
  };

  const QuickSearchToolbar = () => {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  };

  return (
    <DataGrid
      rows={onboardDealers}
      getRowId={(row) => row.Id}
      columns={columns}
      onRowClick={handleRowClick}
      autoHeight
      getRowHeight={() => "auto"}
      disableColumnFilter={true}
      disableColumnMenu={true}
      disableColumnSelector
      disableDensitySelector
      disableVirtualization
      pageSizeOptions={[5]}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
        toolbar: QuickSearchToolbar,
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      sx={{
        "& .muidatagrid-colcelltitle ": {
          display: "block",
          textAlign: "center",
          width: "100%",
        },
      }}
    />
  );
};

export default ClientGrid;
