import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../AuthContext";
import invoices from "../assets/invoices.svg";
import agreements from "../assets/agreements.svg";
import revenue from "../assets/revenue.svg";
import DealerInfo from "../sections/dealerdetails/DealerInfo";
import DealerCards from "../sections/dealerdetails/DealerCards";
import TenantsList from "../components/TenantsList";

import { getTenantByfDealerId } from "../services/tenantServices";

import { getDealerDetailsCardData } from "../services/dealerServices";
import AgreementsClaimsTabs from "../components/AgreementsClaimsTabs";
import JBWReleasaNotesDialog from "../components/JBWReleasaNotesDialog";

const DealerDetails = () => {
  const { user, dealer, group } = useContext(AuthContext);
  const [tenantList, setTenantList] = useState([]);
  const [cardsData, setCardsData] = useState(null);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);

  useEffect(() => {
    setSelectedDealer((values) => JSON.parse(localStorage.getItem("dealer")));
  }, []);
  useEffect(() => {
    setSelectedDealer((values) => JSON.parse(localStorage.getItem("dealer")));
  }, [dealer]);
  console.log("Dealer Details dealer:", selectedDealer);
  // console.log("Dealer Details user:", user);
  const trmRef = useRef();
  const onSync = () => {
    trmRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const getTenants = () => {
    console.log("loading tenant list");
    setTenantList([]);
    getTenantByfDealerId(selectedDealer.dealerId)
      .then(
        (res) => {
          let data = res.data;
          if (data && data.length > 0) {
            setTenantList(data);
          }
        },
        (err) => {
          console.log("Unable retrive tenats data", err);
        }
      )
      .catch((err) => {
        console.error("tenant service Error :", err);
      });
  };

  const getCardsData = () => {
    getDealerDetailsCardData(selectedDealer.dealerId)
      .then(
        (res) => {
          console.log("cards data :", res.data);
          const data = res.data.body;
          const statusCode = res.data.statusCode;
          if (statusCode === 200 && data.length > 0) {
            setCardsData(data[0]);
          }
        },
        (err) => {
          console.log("Unable retrive cards data", err);
        }
      )
      .catch((err) => {
        console.error("CardsData service Error :", err);
      });
  };

  useEffect(() => {
    if (selectedDealer) {
      getTenants();
      getCardsData();
    }
  }, [selectedDealer]);

  const handleSelectedTenant = (param) => {
    setSelectedTenant(param);
  };
  const handleRestTenant = () => {
    console.log("reset tenant called");
    setSelectedTenant(null);
    getTenants();
  };

  return (
    <Box component="main">
      <Container maxWidth="xl" spacing={1}>
        {!selectedDealer ? (
          <Box>
            <Typography component="h3" variant="h3">
              Dealer Is required
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={6}>
              <DealerInfo
                dealer={selectedDealer}
                user={user}
                onSync={onSync}
                sx={{ maxWidth: "100%", height: "90%", borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <DealerCards
                value={
                  cardsData?.totalInvoicesProcessed
                    ? cardsData?.totalInvoicesProcessed
                    : 0
                }
                imgsrc={invoices}
                titletxt={"Total Invoices Processed"}
                sx={{ maxWidth: "100%", height: "90%", borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <DealerCards
                value={
                  cardsData?.agreementsCreated
                    ? cardsData?.agreementsCreated
                    : 0
                }
                imgsrc={agreements}
                titletxt={"Agreements Created"}
                sx={{ maxWidth: "100%", height: "90%", borderRadius: "10px" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <DealerCards
                value={
                  cardsData?.totalClaims ? `${cardsData?.totalClaims}` : `0`
                }
                imgsrc={revenue}
                titletxt={"Claims Created"}
                sx={{ maxWidth: "100%", height: "90%", borderRadius: "10px" }}
              />
            </Grid>
            {selectedDealer.crmSystem !== "Workiz" && (
              <Grid item xs={12} sm={12} lg={12}>
                <TenantsList
                  tenantList={tenantList}
                  updateTenantsList={getTenants}
                  onSelectedTenant={handleSelectedTenant}
                  onRestTenant={handleRestTenant}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} lg={12}>
              {/* <AgreementList displayType="dealers" dealer={selectedDealer} /> */}
              <AgreementsClaimsTabs
                displayType="dealers"
                dealer={selectedDealer}
                showDealer={false}
                tenant={selectedTenant}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} lg={12} ref={trmRef}>
              <TransmissionDetailsList
                displayType="dealers"
                dealer={selectedDealer}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6} lg={3} ref={trmRef}>
              <TransmissionDetails />
            </Grid> */}
            {group === "jbw-dealer" && <JBWReleasaNotesDialog />}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default DealerDetails;
