import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClientProgressBar from "../../components/ClientProgressBar";
import { getNonOnboardedDealers } from "../../services/dealerServices";
import { AuthContext } from "../../AuthContext";

const ClientName = () => {
  let { setDealer, setOnboarding, onBoarding } = useContext(AuthContext);
  let navigate = useNavigate();
  let urlLocation = useLocation();
  const [dealerList, setDealerList] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [clientErr, setClientErr] = useState("");

  useEffect(() => {
    getNonOnboardedDealers()
      .then(
        (res) => {
          const data = res.data;
          if (data && data.length > 0) {
            let dealerData = data.map((d) => ({ ...d, dealerId: d.id }));
            setDealerList(dealerData);
          }
        },
        (err) => {
          console.log(err);
          setClientErr("Uable to Load Clients");
        }
      )
      .catch((err) => {
        console.log(err);
        setClientErr("Internal server error");
      });
  }, []);

  const clientLocationHandler = () => {
    setClientErr("");
    if (selectedDealer) {
      setDealer(selectedDealer);
      setOnboarding({
        ...onBoarding,
        dealerId: selectedDealer.dealerId,
        dealerName: selectedDealer.dealerName,
        dealerRegistered: selectedDealer.registered,
      });
      navigate("/home/onboarding/clientlocation", {
        state: { key: urlLocation.state?.key },
      });
    } else {
      setClientErr("Please select Client");
    }
  };
  const cancelnHandler = () => {
    setClientErr("");
    if (urlLocation.state?.key === "onboardinghome") {
      navigate("/home/onboardinghome");
    } else {
      navigate("/home/overview");
    }
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "80vh" }}
    >
      <Grid item xs={3}>
        <Typography variant="h3" component="h3">
          What is your client name?
        </Typography>
      </Grid>
      <Grid item xs={3} sx={{ mt: 2 }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="body2"
            component="div"
            sx={{ color: "#a8a8a8d4" }}
          >
            Share the details of the new client you are onbaording
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="body2"
            component="div"
            sx={{ color: "#a8a8a8d4" }}
          >
            Don't worry you can change these details later
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ flexGrow: 1, mt: 3 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 450,
            padding: 1,
          }}
        >
          <ClientProgressBar value={16.67} />
          <Typography sx={{ ml: 2 }}> 1/5</Typography>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 450,
            padding: 1,
          }}
        >
          <Autocomplete
            disablePortal
            id="client-search-combo"
            options={dealerList}
            value={selectedDealer}
            isOptionEqualToValue={(option, value) =>
              option.dealerId === value.dealerId
            }
            getOptionLabel={(option) => option.dealerName}
            onChange={(event, value) => {
              console.log(value);
              setSelectedDealer(value);
            }}
            sx={{
              width: 450,
              borderRadius: "50px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
              "input[type='search']::-webkit-search-cancel-button": {
                display: "none",
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    legend: {
                      marginLeft: "30px",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    paddingLeft: "35px",
                  },
                }}
                {...params}
                label="Client"
                placeholder="Enter or Search your Client Name"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: 600,
            padding: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button
            variant="text"
            color="error"
            size="small"
            onClick={cancelnHandler}
          >
            Cancel
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              borderRadius: "50px",
            }}
            onClick={clientLocationHandler}
          >
            Location
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body" sx={{ color: "#f44336", fontSize: "10px" }}>
          {clientErr}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ClientName;
