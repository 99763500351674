import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { useNavigate, useLocation } from "react-router-dom";
import ClientProgressBar from "../../components/ClientProgressBar";
import JbwSwitch from "../../components/JbwSwitch";
import { AuthContext } from "../../AuthContext";
import IconTextField from "../../components/IconTextField";
import WorkIcon from "@mui/icons-material/Work";

const ClientCustom = () => {
  let { isOnboarded, setOnboarding, onboarding, group } =
    useContext(AuthContext);
  let navigate = useNavigate();
  let urlLocation = useLocation();
  const [fieldsChecked, setFieldsChecked] = useState(false);
  const [tagsChecked, setTagsChecked] = useState(false);
  const [customErr, setCustomErr] = useState("");
  const [jobId, setJobId] = useState("");
  const [jobIdErr, setJobIdErr] = useState("");

  useEffect(() => {
    if (onboarding && onboarding.customFields) {
      const custom = onboarding.customFields;
      setFieldsChecked(custom.fieldsChecked);
      setTagsChecked(custom.tagsChecked);
      setJobId(custom.jobId);
    }
  }, [onboarding]);

  const handleFieldsChecked = (event) => {
    console.log("fields Checked", event);
    setFieldsChecked(event);
  };
  const handleTagsChecked = (event) => {
    console.log("tags Checked", event);
    setTagsChecked(event);
  };

  const clientLocationHandler = () => {
    navigate("/home/onboarding/clientplatform", {
      state: { key: urlLocation.state?.key },
    });
  };
  const clientidHandler = (e) => {
    e.preventDefault();
    setCustomErr("");
    setJobIdErr("");
    if (!fieldsChecked && !tagsChecked) {
      setCustomErr(
        "Custom Fields and Custom Tags created at Service Titans are Required"
      );
      return;
    } else if (!fieldsChecked) {
      setCustomErr("Custom Fields were created in Service titan is Required");
      return;
    } else if (!tagsChecked) {
      setCustomErr("Custom Tags were created in Service titan is Required");
      return;
    } else if (jobId === "") {
      setJobIdErr("Please enter job id");
      return;
    } else {
      const customFields = { fieldsChecked, tagsChecked, jobId };
      setOnboarding({ ...onboarding, customFields });
      navigate("/home/onboarding/clientid", {
        state: { key: urlLocation.state?.key },
      });
    }
  };
  const cancelnHandler = () => {
    setOnboarding(null);
    if (urlLocation.state?.key === "tenantlist") {
      navigate("/home/dealerdetails");
    } else if (urlLocation.state?.key === "onboardinghome") {
      navigate("/home/onboardinghome");
    } else if (isOnboarded && group !== "jbw-dealer") {
      navigate("/home/overview");
    }
  };
  const formInputChange = (formField, value) => {
    if (formField === "jobId") {
      setJobId(value);
    }
  };

  return (
    <Box component="form" noValidate sx={{ mt: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "80vh" }}
      >
        <Grid item xs={3}>
          <Typography variant="h5" component="h5">
            Any Custom fields and Tags were created?
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ mt: 2 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#a8a8a8d4" }}
            >
              Share the details of the new client you are onbaording
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#a8a8a8d4" }}
            >
              Don't worry you can change these details later
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ flexGrow: 1, mt: 3 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 450,
              padding: 1,
            }}
          >
            <ClientProgressBar value={66.64} />
            <Typography sx={{ ml: 2 }}> 4/5</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 450,
              padding: 1,
            }}
          >
            <Typography variant="body2" component="div" sx={{ mr: 5 }}>
              Custom Fields were created in Service titan ?
            </Typography>
            <JbwSwitch
              isChecked={handleFieldsChecked}
              setChecked={fieldsChecked}
              id="fieldsSwitch"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 450,
              padding: 1,
            }}
          >
            <Typography variant="body2" component="div" sx={{ mr: 5 }}>
              Custom Tags were created in Service titan ?
            </Typography>
            <JbwSwitch
              isChecked={handleTagsChecked}
              setChecked={tagsChecked}
              id="tagsSwitch"
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 450,
              padding: 1,
            }}
          >
            <Stack>
              <Typography variant="body2" component="div" sx={{ pl: 3, pr: 3 }}>
                Please enter the Job Id for the sample job created in ST as per
                Instructions
              </Typography>
              <IconTextField
                iconStart={<WorkIcon sx={{ color: "#bdbdbd", fontSize: 20 }} />}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="jobId"
                label="Job ID"
                name="jobId"
                size="small"
                value={jobId}
                onChange={(e) => formInputChange("jobId", e.target.value)}
                error={jobIdErr ? true : false}
                helperText={jobIdErr}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: 600,
              padding: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="text"
              color="error"
              size="small"
              onClick={cancelnHandler}
            >
              Cancel
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                borderRadius: "50px",
                mr: 2,
              }}
              onClick={clientLocationHandler}
            >
              Platform
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                borderRadius: "50px",
              }}
              onClick={clientidHandler}
            >
              Tenant Info
            </Button>
          </Box>
        </Grid>
        <Typography variant="body" sx={{ color: "#f44336", fontSize: "10px" }}>
          {customErr}
        </Typography>
      </Grid>
    </Box>
  );
};

export default ClientCustom;
