import { Autocomplete, Box, Button, Grid, TextField, Typography } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClientProgressBar from "../../components/ClientProgressBar";
import { AuthContext } from "../../AuthContext";

const ClientPlatform = () => {
    let navigate = useNavigate();
    let urlLocation = useLocation();
    console.log("urlLocation :", urlLocation.state.key);
    let { dealer, isOnboarded, setOnboarding, onboarding, group } =
        useContext(AuthContext);
    console.log("clientLocation dealer:", dealer);
    // const [tenantName, setTenantName] = useState("");
    // const [addressLine, setAddressLine] = useState("");
    // const [zipCode, setZipCode] = useState("");
    // const [city, setCity] = useState("");
    // const [dealersState, setDealerState] = useState("");
    const [platformList, setPlatformList] = useState([{ "platformId": 1, "platformName": "Service Titan" }, { "platformId": 2, "platformName": "WorkIZ" }]);
    const [selectedPlatform, setSelectedPlatform] = useState({ "platformId": 1, "platformName": "Service Titan" });

    useEffect(() => {
        // if (urlLocation.state?.key !== "tenantlist") {
        //     setTenantName(dealer?.dealerName);
        // }

        // setAddressLine(dealer?.addressline_1 ? dealer?.addressline_1 : "");
        // setZipCode(dealer?.zipcode ? dealer?.zipcode : "");
        // setCity(dealer?.city ? dealer?.city : "");
        // setDealerState(dealer?.state ? dealer?.state : "");
    }, [dealer]);

    const clientNameHandler = () => {
        navigate("/home/onboarding/clientlocation", {
            state: { key: urlLocation.state?.key },
        });
    };
    const clientCustomHandler = () => {
        // let location = {
        //     tenantName: tenantName,
        //     addressLine: addressLine,
        //     city: city,
        //     state: dealersState,
        //     zipcode: zipCode,
        // };
        // setOnboarding({ ...onboarding, location });
        if (selectedPlatform.platformId === 1) {
            navigate("/home/onboarding/clientcustome", {
                state: { key: urlLocation.state?.key },
            });
        } else if (selectedPlatform.platformId === 2) {
            navigate("/home/onboarding/clientaccountid", {
                state: { key: urlLocation.state?.key },
            });
        }

    };
    const cancelnHandler = () => {
        if (urlLocation.state?.key === "tenantlist") {
            navigate("/home/dealerdetails");
        } else if (urlLocation.state?.key === "onboardinghome") {
            navigate("/home/onboardinghome");
        } else if (isOnboarded && group !== "jbw-dealer") {
            navigate("/home/overview");
        }
    };

    // const formInputChange = (formField, value) => {
    //     if (formField === "tenantName") {
    //         setTenantName(value);
    //     }
    //     if (formField === "addressLine") {
    //         setAddressLine(value);
    //     }
    //     if (formField === "zipCode") {
    //         setZipCode(value);
    //     }
    //     if (formField === "city") {
    //         setCity(value);
    //     }
    //     if (formField === "state") {
    //         setDealerState(value);
    //     }
    // };

    return (
        <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "80vh" }}
            >
                <Grid item xs={3}>
                    <Typography variant="h5" component="h5">
                        {`Select the platform ${dealer?.dealerName} is using`}
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={{ mt: 2 }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{ color: "#a8a8a8d4" }}
                        >
                            Share the details of the new client you are onbaording
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{ color: "#a8a8a8d4" }}
                        >
                            Don't worry you can change these details later
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ flexGrow: 1, mt: 3 }}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: 450,
                            padding: 1,
                        }}
                    >
                        <ClientProgressBar value={selectedPlatform.platformId === 1 ? 40 : 50} />
                        <Typography sx={{ ml: 2 }}>
                            {selectedPlatform.platformId === 1 ? "3/5" : "3/4"}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: 450,
                            padding: 1,
                        }}
                    >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            id="client-search-combo"
                            options={platformList}
                            value={selectedPlatform}
                            isOptionEqualToValue={(option, value) =>
                                option.platformId === value.platformId
                            }
                            getOptionLabel={(option) => option.platformName}
                            onChange={(event, value) => {
                                console.log(value);
                                setSelectedPlatform(value);
                            }}
                            sx={{
                                width: 450,
                                borderRadius: "50px",
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "50px",
                                },
                                "input[type='search']::-webkit-search-cancel-button": {
                                    display: "none",
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            legend: {
                                                marginLeft: "30px",
                                            },
                                        },
                                        "& .MuiInputLabel-outlined": {
                                            paddingLeft: "35px",
                                        },
                                    }}
                                    {...params}
                                    label="Platform"
                                    placeholder="Enter or Search your Client Name"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: "search",
                                    }}

                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: 600,
                            padding: 1,
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        {isOnboarded && group !== "jbw-dealer" && (
                            <Button
                                variant="text"
                                color="error"
                                size="small"
                                onClick={cancelnHandler}
                            >
                                Cancel
                            </Button>
                        )}
                        <Box sx={{ flexGrow: 1 }} />
                        {isOnboarded &&
                            group !== "jbw-dealer" &&
                            urlLocation.state?.key !== "tenantlist" && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    sx={{
                                        borderRadius: "50px",
                                        mr: 2,
                                    }}
                                    onClick={clientNameHandler}
                                >
                                    Client Location
                                </Button>
                            )}

                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            sx={{
                                borderRadius: "50px",
                            }}
                            onClick={clientCustomHandler}
                        >
                            {selectedPlatform.platformId === 1 ? "Custom Fields" : "Account Info"}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientPlatform;
