import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClientProgressBar from "../../components/ClientProgressBar";
import IconTextField from "../../components/IconTextField";
import { AuthContext } from "../../AuthContext";
import { getTenantById } from "../../services/tenantServices";
import { verifyWorkizAccount } from "../../services/JBWServiceWorkizService";
import { getCustomData } from "../../services/customDataServices";

const ClientAccountId = () => {
  let navigate = useNavigate();
  let urlLocation = useLocation();
  let { dealer, isOnboarded, setOnboarding, onboarding, group } =
    useContext(AuthContext);
  console.log("ClientId:", dealer);
  const [accountId, setAccountId] = useState("");
  // const [clientId, setClientId] = useState("");
  // const [secretId, setSecretId] = useState("");
  const [accountIdErr, setAccountIdErr] = useState("");
  // const [clientIdErr, setClientIdErr] = useState("");
  // const [secretIdErr, setSecretIdErr] = useState("");
  const [isValidTenant, setIsValidTenant] = useState(false);
  const [clientInfoErr, setClientInfoErr] = useState("");
  // const [customTagList, setCustomTagList] = useState([]);
  // const [customFieldList, setCustomFieldList] = useState([]);
  // const [jobId, setJobId] = useState("");

  useEffect(() => {
    if (onboarding && onboarding.clientInfo) {
      const clientInfo = onboarding.clientInfo;
      // setClientId(clientInfo.clientId);
      // setSecretId(clientInfo.clientSecret);
      setAccountId(clientInfo.tenantId);
    }

    // if (onboarding && onboarding.customFields) {
    //   const custom = onboarding.customFields;
    //   setJobId(custom.jobId);
    // }
    // getCustomData()
    //   .then(
    //     (res) => {
    //       console.log("custom data respo :", res);
    //       const data = res.data;
    //       const tempCTags = data.customTags;
    //       const tempCFields = data.customFields;
    //       if (tempCTags && tempCTags.length > 0) {
    //         const temp = [];
    //         tempCTags.forEach((ct) => {
    //           temp.push(ct.tagName);
    //         });
    //         setCustomTagList(temp);
    //       }
    //       if (tempCFields && tempCFields.length > 0) {
    //         const temp = [];
    //         tempCFields.forEach((ct) => {
    //           temp.push(ct.fieldName);
    //         });
    //         setCustomFieldList(temp);
    //       }
    //     },
    //     (err) => {
    //       console.log("custome data service error", err);
    //     }
    //   )
    //   .catch((err) => {
    //     console.error("custom data error", err);
    //   });
  }, [onboarding]);

  const clientCustomHandler = () => {
    navigate("/home/onboarding/clientplatform", {
      state: { key: urlLocation.state?.key },
    });
  };
  const clientConnectHandler = (event) => {
    event.preventDefault(); // Prevent default form submission
    setAccountIdErr("");
    setIsValidTenant(false);
    setClientInfoErr("");

    validation()
      .then((res) => {
        console.log("Validation passed:", res);
        verifyWorkizAccount(accountId)
          .then((response) => {
            console.log("Workiz response:", response);
            const data = response.data;
            console.log("Workiz data:", data);
            console.log("Workiz  response status:", response.status);
            if (response.status === 200) {
              if (data === "Successful") {
                // Handle successful verification
                setOnboarding({ ...onboarding, accountId });
                navigate("/home/onboarding/clientconnect", {
                  state: { key: urlLocation.state?.key, workizSetup: true },
                });
              } else {
                setClientInfoErr("Invalid WorkIZ Account ID");
                setIsValidTenant(true);
              }
            } else {
              setClientInfoErr("Error verifying WorkIZ Account ID");
            }
          })
          .catch((error) => {
            console.error("WorkIZ verification error:", error);
            setClientInfoErr("Unable to connect to WorkIZ. Please try again.");
          });
      })
      .catch((validationError) => {
        console.error("Validation error:", validationError);
        setAccountIdErr("Account ID is required");
      });
  };

  const cancelnHandler = () => {
    setOnboarding(null);
    if (urlLocation.state?.key === "tenantlist") {
      navigate("/home/dealerdetails");
    } else if (urlLocation.state?.key === "onboardinghome") {
      navigate("/home/onboardinghome");
    } else if (isOnboarded && group !== "jbw-dealer") {
      navigate("/home/overview");
    }
  };

  // const tenantIdValidation = () => {
  //   return new Promise((resolve, reject) => {
  //     if (accountId === "") {
  //       setAccountIdErr("Tenant ID required");
  //       resolve({ tenantId: "Tenant ID required" });
  //     } else {
  //       resolve({ tenantId: "" });
  //     }
  //     reject("");
  //   });
  // };

  const validation = () => {
    return new Promise((resolve, reject) => {
      if (!accountId) {
        setAccountIdErr("Account ID is Required");
        reject("");
      } else {
        resolve({
          accountId: "",
        });
      }
      reject("");
      // if (clientId === "" && secretId === "" && accountId === "") {
      //   setClientIdErr("Client ID is Required");
      //   setSecretIdErr("Secret ID is required");
      //   setAccountIdErr("Tenant ID is Required");
      //   resolve({
      //     clientId: "Client ID is Required",
      //     secretId: "Secret ID is required",
      //     tenantId: "Tenant ID is Required",
      //   });
      // } else if (clientId === "") {
      //   setClientIdErr("Client ID is Required");
      //   resolve({
      //     clientId: "Client ID is Required",
      //     secretId: "",
      //     tenantId: "",
      //   });
      // } else if (secretId === "") {
      //   setSecretIdErr("Secret ID is required");
      //   resolve({
      //     clientId: "",
      //     secretId: "Secret ID is Required",
      //     tenantId: "",
      //   });
      // } else if (accountId === "") {
      //   setAccountIdErr("Tenant ID is required");
      //   resolve({
      //     clientId: "",
      //     secretId: "",
      //     tenantId: "Tenant ID is Required",
      //   });
      // } else {
      //   resolve({
      //     clientId: "",
      //     secretId: "",
      //     tenantId: "",
      //   });
      // }
      // reject("");
    });
  };

  const formInputChange = (formField, value) => {
    if (formField === "accountId") {
      setAccountId(value);
    }
    // if (formField === "clientId") {
    //   setClientId(value);
    // }
    // if (formField === "secretId") {
    //   setSecretId(value);
    // }
  };

  const handleTenantCheck = () => {
    console.log("verifying tenant", accountId);

    setAccountIdErr("");
    // setSecretIdErr("");
    // setClientIdErr("");
    setIsValidTenant(false);
    setClientInfoErr("");
    // tenantIdValidation()
    //   .then(
    //     (res) => {
    //       console.log(res);
    //       if (res.tenantId === "") {
    //         //service call to tenantid verification
    //         getTenantById(tenantId)
    //           .then(
    //             (res) => {
    //               let data = res.data;
    //               if (data && data.length > 0) {
    //                 setClientInfoErr("Tenant is already register");
    //               } else {
    //                 setIsValidTenant(true);
    //               }
    //             },
    //             (err) => {
    //               setIsValidTenant(false);
    //               console.log(err);
    //               setClientInfoErr("Unable to verify tenant with the server");
    //             }
    //           )
    //           .catch((err) => {
    //             setIsValidTenant(false);
    //             console.error("tenant verifcation error", err);
    //             setClientInfoErr("Internal Server error");
    //           });
    //       } else {
    //         setIsValidTenant(false);
    //       }
    //     },
    //     (err) => {
    //       setIsValidTenant(false);
    //       setClientInfoErr(err);
    //     }
    //   )
    //   .catch((err) => {
    //     setIsValidTenant(false);
    //     setClientInfoErr(err);
    //   });
  };

  return (
    <Box component="form" noValidate sx={{ mt: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "80vh" }}
      >
        <Grid item xs={3}>
          <Typography variant="h5" component="h5">
            Enter Workiz Account ID
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ mt: 2 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#a8a8a8d4" }}
            >
              Share the details of the new client you are onbaording
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#a8a8a8d4" }}
            >
              Don't worry you can change these details later
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ flexGrow: 1, mt: 3 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 450,
              padding: 1,
            }}
          >
            <ClientProgressBar value={80} />
            <Typography sx={{ ml: 2 }}> 4/4</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 450,
              padding: 1,
            }}
          >
            <IconTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="accountId"
              label="Account ID"
              value={accountId}
              autoFocus
              onChange={(e) => {
                formInputChange("accountId", e.target.value);
              }}
              onBlur={handleTenantCheck}
              name="accountId"
              size="small"
              error={accountIdErr ? true : false}
              helperText={accountIdErr}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              width: 600,
              padding: 1,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="text"
              color="error"
              size="small"
              onClick={cancelnHandler}
            >
              Cancel
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                borderRadius: "50px",
                mr: 2,
              }}
              onClick={clientCustomHandler}
            >
              Platform
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                borderRadius: "50px",
              }}
              onClick={clientConnectHandler}
            >
              Connect to WorkIZ
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body"
            sx={{ color: "#f44336", fontSize: "10px" }}
          >
            {clientInfoErr}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientAccountId;
