import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Avatar from "react-avatar";
import moment from "moment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CopyToClipboardButton from "../../components/CopyToClipboardButton";
import { useNavigate } from "react-router-dom";

const DealerInfo = (props) => {
  const { sx, onSync, dealer } = props;
  let navigate = useNavigate();
  // console.log("dealer info user:", user);
  console.log("dealer info dealer:", dealer);
  const handleSync = (event) => {
    event.preventDefault();
    // onSync();
    navigate("/home/transactionlog");
  };

  const platformUrl = [
    {
      plotform: "Service Titan",
      url: "https://developer.servicetitan.io/content/st-logo-small-black.png",
    },
    {
      plotform: "Workiz",
      url: "https://jbwarrantiestests.tests.workiz.com/_assets/img/workizlogo370.svg",
    },
  ];
  return (
    <Card sx={sx} raised={true}>
      <CardContent sx={{ p: 1 }}>
        {dealer && (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Box
                component="section"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Avatar
                  name={dealer?.dealerName?.replace(/[^a-zA-Z ]/g, "")}
                  size="100"
                  round={true}
                  maxInitials={2}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  title={dealer?.dealerName}
                />
                <Box
                  component="img"
                  sx={{
                    mb: 1,
                    height: 30,
                    width: 100,
                    maxHeight: { xs: 20, md: 25 },
                    maxWidth: { xs: 50, md: 75 },
                  }}
                  alt={`${dealer?.crmSystem} logo`}
                  src={
                    platformUrl.find((pu) => pu.plotform === dealer?.crmSystem)
                      ?.url
                  }
                />

                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    borderRadius: "20px",
                    height: "20%",
                    width: "100%",
                  }}
                  onClick={handleSync}
                >
                  Sync Details
                </Button>
              </Box>
            </Grid>
            <Grid item xs={9} sx={{ pl: 8, pt: 8 }}>
              <Box component="section" sx={{ mt: 1, ml: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      align="left"
                      sx={{ fontSize: "12px" }}
                    >
                      Dealer Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="caption"
                      sx={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      {dealer?.dealerName}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      align="left"
                      sx={{ fontSize: "12px" }}
                    >
                      Dealer ID
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="caption"
                      sx={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      {dealer?.dealerId}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      align="left"
                      sx={{ fontSize: "12px" }}
                    >
                      Location
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="overcaptionline"
                      sx={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      {dealer?.addressline_1},{dealer?.city},{dealer?.state},
                      {dealer?.zip}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      align="left"
                      sx={{ fontSize: "12px" }}
                    >
                      Last Sync Date and Time
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="overcaptionline"
                      sx={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      {moment(dealer?.syncEndDate).format("MMM.DD, YYYY hh:mm")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      align="left"
                      sx={{ fontSize: "12px" }}
                    >
                      Registered Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      variant="overcaptionline"
                      sx={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      {dealer?.registeredName} | {"  "}
                      {dealer?.registeredEmail}
                      <CopyToClipboardButton
                        copyedtext={dealer?.registeredEmail}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default DealerInfo;
